import React, { useEffect } from "react"
import { Col, Row, Container as BTContainer } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { device } from "../../constants/breakpoints"
import { colors } from "../../constants/Colors"
import { GlobalRow } from "../../constants/global-styles"
import OfferContactFormContainer from "../../containers/home/OfferContactForm"
import {
  selectCosaOffriamoLoading,
  selectCosaOffriamoPage,
  startFetchingCosaOffriamoAsync,
} from "../../redux/cosaoffriamoSlice"
import ButtonOverlay from "../Atoms/ButtonOverlay"
import LoadingView from "../LoadingView/LoadingView"

const descrizioneMock =
  "Lorem ipsum dolor sit amet, consecteur adipiscing elit. Pellentesque lacinia nunc vel commodo ultrices. Nulla vehicula, ex sed tempus pharetra, dolor tincidunt magna, ut ornare risus nisl quis arcum. Duis nec euisomd dui a mollis"

const Points = [
  {
    title: "Crea il tuo profilo",
    body:
      "Registrati e crea il tuo profilo professionale  Gestisci foto, video, informazioni, richieste",
  },
  {
    title: "Gestisci le promozioni",
    body: "Renditi visible con le tue promozioni \n lorem ipsum",
  },
  {
    title: "Effettua videoconsulti",
    body: descrizioneMock,
  },
  {
    title: "Rimani in contatto con i pazienti",
    body: descrizioneMock,
  },
]
const baseItems = [
  "Profilo personale",
  "Pubblica fino a 6 immagini",
  "Minor visibilità nel portale",
  "Maggior concorrenza nel tuo profilo",
  "x",
  "x",
  "x",
  "x",
]

const premiumItems = [
  "Profilo personale",
  "Pubblica immagini senza limiti",
  "Maggior visibilità nel portale",
  "Minor concorrenza",
  "Videoconsulto disponibile",
  "Pubblica immagini senza limiti",
  "Maggior visilità nel portale",
]
const Container = styled.div`
  border-top: 20px solid black;
  border-top-color: ${({ theme }) => theme.ocean};
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
`

const Premium = styled.div`
  background: ${({ theme }) => theme.midblue};
  width: 35px;
  height: 35px;
  position: absolute;
  background-image: url(${props => props.img});
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  right: 30px;
  top: 0;
`
const Title = styled.h1`
  color: ${({ theme }) => theme.blackblue};
`
const SubTitle = styled.p`
  color: ${({ theme }) => theme.lightblue};
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: -5px;
`

const Background = styled(Row)`
  min-height: 400px;
  --bs-gutter-x: 0;
  object-fit: cover;
  background-image: url(${props => props.img});
  background-size: cover;
  background-position: 100% 0;
  background-size: 100% 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 150px;
  //  background: url(${props => props.img}) center center/contain no-repeat;
`

const SubscriptionCard = styled.div`
  position: relative;
  background: white;
  border-radius: 12px;
  text-align: center;
  padding: 10px;
  height: 500px;
  max-width: 480px;
  box-shadow: 5px 5px 20px #0260d91a;
  margin: auto;

  padding: 20px;

  @media ${device.desktop} {
    margin-top: 50px;
  }

  @media ${device.tablet} {
    border-bottom: 1px solid #f7f7f7;
    border-radius: 0;
  }
`

const CardTitle = styled.h4`
  color: ${({ theme }) => theme.blue};
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 30px;
`

const CardContent = styled.p`
  line-height: 1.3em;
`

const Content = styled.p``

const TitlePoint = styled.h3`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.midblue};
`
const Point = styled(Col)`
  padding-left: 5%;
  margin-bottom: 50px;
`

export default function CosaOffriamoContainer() {
  const loading = useSelector(selectCosaOffriamoLoading)
  const page = useSelector(selectCosaOffriamoPage)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(startFetchingCosaOffriamoAsync())
  }, [])

  if (loading) {
    return <LoadingView />
  }
  return (
    <Container>
      <TitleWrapper>
        <Title>{page["block_1_title"].nome}</Title>
        <SubTitle>{page["block_1_title"].descrizione}</SubTitle>
      </TitleWrapper>
      <div style={{ height: 50 }} />
      <BTContainer style={{ paddingBottom: 50 }}>
        <Row>
          <Point data-aos="fade-zoom-in" data-aos-delay={100} md={6}>
            <TitlePoint>{page["block_1_item_1"].nome}</TitlePoint>
            <Content>{page["block_1_item_1"].descrizione}</Content>
          </Point>
          <Point data-aos="fade-zoom-in" data-aos-delay={200} md={6}>
            <TitlePoint>{page["block_1_item_2"].nome}</TitlePoint>
            <Content>{page["block_1_item_2"].descrizione}</Content>
          </Point>
          <Point data-aos="fade-zoom-in" data-aos-delay={300} md={6}>
            <TitlePoint>{page["block_1_item_3"].nome}</TitlePoint>
            <Content>{page["block_1_item_3"].descrizione}</Content>
          </Point>
          <Point data-aos="fade-zoom-in" data-aos-delay={400} md={6}>
            <TitlePoint>{page["block_1_item_4"].nome}</TitlePoint>
            <Content>{page["block_1_item_4"].descrizione}</Content>
          </Point>
        </Row>
      </BTContainer>
      <TitleWrapper
        style={{ borderTop: "3px solid #f7f7f7", paddingBottom: 40 }}
      >
        <Title>{page["block_2_title"].nome}</Title>
        <SubTitle>{page["block_2_title"].descrizione}</SubTitle>
      </TitleWrapper>
      <Background
        className="justify-content-md-center"
        img={require("../../images/background_chi_siamo.png")}
      >
        <Col xl={5} xxl={4}>
          <SubscriptionCard>
            <CardTitle>{page["block_2_card_1"].nome}</CardTitle>
            <div style={{ minHeight: 320 }}>
              {page["block_2_card_1"].descrizione.split(";").map((item, i) => (
                <CardContent key={"item" + i}>{item}</CardContent>
              ))}
            </div>
            <ButtonOverlay>Registrati</ButtonOverlay>
          </SubscriptionCard>
        </Col>
        <Col xl={5} xxl={4}>
          <SubscriptionCard>
            <Premium img={require("../../images/badge.png")} />
            <CardTitle>{page["block_2_card_2"].nome}</CardTitle>
            <div style={{ minHeight: 320 }}>
              {page["block_2_card_2"].descrizione.split(";").map((item, i) => (
                <CardContent key={"premium" + i}>{item}</CardContent>
              ))}
            </div>
            <ButtonOverlay>Registrati</ButtonOverlay>
          </SubscriptionCard>
        </Col>
      </Background>
      <OfferContactFormContainer />
    </Container>
  )
}
