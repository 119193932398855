import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import CheckBox from "../../components/CheckBox/CheckBox"
import ContactForm from "../../components/Form/Form"
import { Input, InputLarge } from "../../components/Form/styles/frm"
import { colors } from "../../constants/Colors"
import { ACCEPT_TERMS } from "../../constants/popups"
import {
  selectFormLoading,
  sendContactAsync,
} from "../../redux/contactFormSlice"
import { openErrorToast } from "../../redux/toastSlice"

const Title = styled.h3`
  color: ${({ theme }) => theme.midblue};
  text-align: center;
  font-weight: 700;
`

const SubTitle = styled.h6`
  color: ${({ theme }) => theme.midblue};
  text-align: center;
  font-weight: 500;
`
export default function OfferContactFormContainer() {
  const { register, handleSubmit } = useForm()
  const [accept, setAccept] = useState(false)
  const loading = useSelector(selectFormLoading)
  const dispatch = useDispatch()
  const onSubmit = data => {
    if (!accept) {
      dispatch(openErrorToast(ACCEPT_TERMS))
    }

    dispatch(sendContactAsync(data))
  }
  return (
    <ContactForm onSubmit={handleSubmit(onSubmit)}>
      <ContactForm.Wrapper style={{ paddingBottom: 100 }} data-aos="fade-up">
        <Title>Vuoi avere altre informazioni?</Title>
        <SubTitle>Compila il form e ti contatteremo</SubTitle>
        <ContactForm.Row>
          <Input
            type="text"
            required
            {...register("nome")}
            placeholder="Nome"
          />
          <Input
            type="text"
            required
            {...register("cognome")}
            placeholder="Nome"
          />
        </ContactForm.Row>
        <ContactForm.Row>
          <Input
            required
            type="email"
            {...register("email")}
            placeholder="Email"
          />
          <Input type="text" {...register("comune")} placeholder="Città" />
        </ContactForm.Row>
        <InputLarge
          required
          type="text"
          {...register("messaggio")}
          placeholder={"Messaggio"}
        />
        <CheckBox onClick={() => setAccept(!accept)}>
          continuando accetti i termini e condizioni sulla privacy
          <CheckBox.Input
            type="checkbox"
            checked={accept ? "checked" : false}
          />
          <CheckBox.CheckMark></CheckBox.CheckMark>
        </CheckBox>
        <ContactForm.Button loading={loading} value={"Invia"} type="submit" />
        <Link
          to={"/"}
          style={{
            cursor: "pointer",
            textDecoration: "none",
            textAlign: "center",
            marginTop: 40,
            color: colors.LIGHT_BLUE,
          }}
        >
          <FontAwesomeIcon
            style={{ fontSize: 11, marginLeft: 5, fontWeight: 100 }}
            icon={faArrowLeft}
          />
          <span style={{ fontSize: 14, fontWeight: 400, marginLeft: 10 }}>
            Torna a chirurghi estetici
          </span>
        </Link>
      </ContactForm.Wrapper>
    </ContactForm>
  )
}
