import React from "react"
import styled from "styled-components"

export const ButtonOverlayStyle = styled.button`
  border-color: ${props => props.color};
  background: white;
  border-radius: 10px;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  border-width: 0.1em;
  min-width: 150px;
  border-style: solid;
  color: ${props => props.color};
  font-size: 14px;
  margin-right: 5px;
  padding-right: 20px;
  padding-left: 20px;
  font-weight: 600;
`

export default function ButtonOverlay({
  color = "#337ab7",
  children,
  ...restProps
}) {
  return (
    <ButtonOverlayStyle {...restProps} color={color}>
      {children}
    </ButtonOverlayStyle>
  )
}
