import React from "react"
import SEO from "../components/seo"

import "../constants/global.css"
import Aos from "aos"
import "aos/dist/aos.css"
import LayoutChirurghi from "../components/layoutChirurghi"
import CosaOffriamoContainer from "../components/CosaOffriamoContainer/CosaOffriamoContainer"

const CosaOffriamoPage = ({ location }) => {
  React.useEffect(() => {
    Aos.init()
  }, [])
  return (
    <LayoutChirurghi pathname={location.pathname}>
      <SEO title="Cosa Offriamo" />
      <CosaOffriamoContainer />
    </LayoutChirurghi>
  )
}

export default CosaOffriamoPage
